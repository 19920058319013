import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('../views/home/Index.vue'),
    // redirect:"/index",
    children: [
      { path: '', component: () => import('@/views/home/HomeBody/Main.vue') },
      { path: 'successStories', component: () => import('@/views/home/HomeBody/SuccessStories.vue') },
      { path: 'about', component: () => import('@/views/home/HomeBody/About.vue') },
      { path: 'freeuse', component: () => import('@/views/home/HomeBody/FreeUse.vue') },
      {
        path: 'login',
        component: () => import('@/views/home/HomeLogin.vue'),

      },
      { path: "forgotPassword", component: () => import("@/views/home/ForgotPassword.vue") },
      { path: "resetPassword", component: () => import("@/views/home/ResetPassword.vue") },
      { path: "successResult", component: () => import("@/views/home/HomeBody/SuccessResult.vue") }
    ]
  },

  {
    path: '/main',
    component: () => import('@/views/Main/Index.vue'),
    children: [
      { path: 'home', component: () => import('@/views/Main/MainProjects.vue') },
      { path: 'task', component: () => import('@/views/Main/task/MainTask.vue') },
      { path: 'report/:id/:type', name: "reportDetail", component: () => import('@/views/Main/report/MainReportDetail.vue'), props: true },
      { path: 'testReport/:id/:type', name: "testReport", component: () => import('@/views/Main/report/testReport/index.vue'), props: true },
      {
        path: 'verify', name: 'MainVerify', component: () => import('@/views/Main/MainVerify/Index.vue'), meta: { keepAlive: true }, // 添加 meta 属性指定需要缓存该路由
        children: [
          { path: '', component: () => import('@/views/Main/MainVerify/ProjectDirectory.vue'), },
          { path: 'projects/:id', component: () => import('@/views/Main/MainVerify/fileDirectory/index.vue'), name: 'FileDirectory', }
        ]
      },
      { path: 'setting', component: () => import('@/views/Main/setting/MainSetting.vue') },

    ]
  },
  { path: '/markdown', name: 'Markdown', component: () => import('@/views/Main/marckDown.vue') },
]

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
})

// 访问任何一个页面前都会执行
router.beforeEach((to, from, next) => {
  //滚动条回滚顶部
  window.scrollTo(0, 0)
  const isLogin = localStorage.userToken ? true : false
  const paths = ["/login", "/freeuse", "/successStories", "/", "/forgotPassword", "/resetPassword", "/about", "/successResult"]
  if (paths.includes(to.path)) {
    next()
  } else {
    isLogin ? next() : next("/login")
  }
})
export default router
